/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import PageHeader from "../../components/elements/PageHeader/PageHeader";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(PageHeader, {
    title: "こんな人に学んでほしい"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "今までの勉強のなかで、いちばん興味を持って取り組んできた科目が「英語」だった人。"), "\n", React.createElement(_components.li, null, "これからもっと、英語を勉強することに対して夢中になっていきたいと考えている人。"), "\n", React.createElement(_components.li, null, "今まで遠ざかってきた英語ともう一度ちゃんと向き合って、上達させたいと思っている人。"), "\n", React.createElement(_components.li, null, "英語でも日本語でも、たくさんの人と「話せるようになりたい」という気持ちがある人。"), "\n", React.createElement(_components.li, null, "語学とともに、一流の社会人になるために必要なマナーも身につけて成長していきたい人。"), "\n", React.createElement(_components.li, null, "熱中して取り組める何かがあって、それをもっと極めるために英語力を身につけたい人。"), "\n", React.createElement(_components.li, null, "将来何をするかはわからないけど、とにかく英語を使って活躍していたいと願う人。"), "\n", React.createElement(_components.li, null, "英語に触れることで、さらに自らを高めて成長していきたいという思いがある人。"), "\n", React.createElement(_components.li, null, "英語を使う仕事に就いてスキルを発揮して、社会に貢献できるような存在になりたい人。"), "\n", React.createElement(_components.li, null, "多くの価値観に触れることで、自らの感性をさらに豊かにしていきたいと思っている人。"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
